import {Triangle} from "react-loader-spinner";


const WaitingPage = () => {
    return (
        <div style={{position:"fixed", top:"50%", left:"50%", transform: "translate(-50%, -50%)"}}>
            <Triangle
                height="80"
                width="80"
                color="#00467F"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}
export default WaitingPage