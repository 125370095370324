import {Button} from "react-bootstrap";
import {useFormData} from "../context/FormDataContext";


const Logo = ({data}) => {
    if (data) {
        return <div className="text-center m-5"><img src={data} alt="Logo" style={{maxHeight: "5em", maxWidth: "20em"}}/></div>
    }

    return <></>
}


const InitialPage = ({onSkip}) => {
    let serverData = useFormData();

    return (
        <div className="initialPage">
            <div className="text-center"><img src={serverData.company.logo} style={{width: "4em"}} alt="Logo"/></div>
            <div className="lead m-5" dangerouslySetInnerHTML={{__html: serverData.company.intro}} />

            <Logo data={serverData.logos?.client} />

            <p className="text-center">
                <Button onClick={() => onSkip()} size="lg">Iniciar</Button>
            </p>

            <Logo data={serverData.logos?.financing} />
        </div>
    )
}

export default InitialPage
