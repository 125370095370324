import FormController from "./components/form/FormController";
import {useState} from "react";
import WaitingPage from "./pages/WaitingPage";
import SubmittedPage from "./pages/SubmittedPage";
import BackendService from "./services/BackendService";
import ErrorPage from "./pages/ErrorPage";
import {useSearchParams} from "react-router-dom";
import InitialPage from "./pages/InitialPage";

const ShowForm = ({onSubmit}) => {
    let [pastInitialPage, setPastInitialPage] = useState(false)

    if (!pastInitialPage) {
        return (
            <InitialPage onSkip={() => setPastInitialPage(true)} />
        )
    }

    return (
        <FormController onSubmit={onSubmit}/>
    )
}

function App() {
    let [submitting, setSubmitting] = useState(false)
    let [submitted, setSubmitted] = useState(false)
    let [error, setError] = useState(null)
    let [params] = useSearchParams()

    let _toParams = (searchParams) => {
        const queryParams = {};
        searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });
        return queryParams
    }

    let onSubmit = (data) => {
        setSubmitting(true)
        BackendService.postForm({
            answers: data,
            params: _toParams(params)
        })
            .then(data => {
                if (data.status !== 200) {
                    setError(data)
                } else {
                    setSubmitted(true)
                }
            })
            .catch(e => {
                setError(e)
            })
    }

    if (error) return <ErrorPage error={error} />
    if (submitted) return <SubmittedPage />
    if (submitting) return <WaitingPage />

    return (
        <ShowForm onSubmit={onSubmit}/>
    );
}

export default App;
