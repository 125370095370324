class BackendService {

    fetchForm(params) {
        return fetch(`/api/form?${this._toQueryString(params)}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(d => {
                return d.json()
                    .then(data => Promise.resolve({
                        ...data,
                        status: d.status
                    }))
            })
    }

    _toQueryString(searchParams) {
        const queryParams = {};
        searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });
        return Object.keys(queryParams)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
            .join('&');
    }


    postForm (data) {
        return fetch(`/api/form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(d => {
                return d.json()
                    .then(data => Promise.resolve({
                        ...data,
                        status: d.status
                    }))
            })
    }
}

const instance = new BackendService()

export default instance;