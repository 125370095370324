import React, {createContext, useContext, useEffect, useState} from 'react';
import WaitingPage from "../pages/WaitingPage";
import BackendService from "../services/BackendService";
import {useSearchParams} from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";

let defaultValue = {};
const LoadingContext = createContext(defaultValue);

export const useFormData = () => useContext(LoadingContext);

export const LoadFormData = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({});

    let [params] = useSearchParams();

    useEffect(() => {
        const startTime = Date.now();
        BackendService.fetchForm(params)
            .then(data => {
                const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(1000 - elapsedTime, 0);
                setTimeout(() => {
                    setData(data)
                    setLoaded(true)
                }, remainingTime);
            })
            .catch(e => {
                setLoaded(true)
                setData({
                    ...e,
                    code: "UNKNOWN",
                    status: 500
                })
            })
    }, []);

    if (!loaded) {
        return <WaitingPage />
    }

    if (data.status !== 200) {
        return <ErrorPage error={data} />
    }

    return (
        <LoadingContext.Provider value={data}>
            {children}
        </LoadingContext.Provider>
    );
};
