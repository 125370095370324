import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion} from "@fortawesome/free-solid-svg-icons";

const NotFoundPage = ({}) => {
    return (
        <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <div className="text-center m-3 text-danger"><FontAwesomeIcon icon={faQuestion} size="10x"/></div>
            <h2 className="text-center">Formulário Inválido</h2>
            <p className="text-center">Entre em contacto com a administração</p>
        </div>
    )
}
export default NotFoundPage